import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Stay_Connected from "../home/Stay_Connected";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Hiring from "../Jobs/Hiring";
import { useNavigate, useParams, useLocation, useHistory,Route,Link,Switch } from "react-router-dom";
import Pagination3 from "../../utility/Pagination/Pagination";
import WPAPI from "wpapi";

const BlogDetails=()=>
{
    const [articles, setArticles] = React.useState([]);
    const [blogDetails, setBlogDetails] = useState([]);
    const [blogTitle, setBlogTitle] = useState([]);
    const [blogDescription, setBlogDescription] = useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);

    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 5; // Per page show the data pagination
    //const urlObj = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const blogId = queryParams.get('blogId');

    const wp = new WPAPI({
        endpoint: "https://snv3cms.smallnation.site/wp-json"
      });
    

    
      const fetchPostDetail = async (postId) => {
         //alert(postId);
        // var postInfo = wp.posts().id(postId).get();
        // alert(JSON.stringify(postInfo));
         //setBlogDetails(postInfo);


         wp.posts().id( postId )
         .then(function( result ) {
            console.log(JSON.stringify(result));
            console.log( result.content.protected ); // true
            setBlogDescription( result.content.rendered);
            setBlogTitle( result.title.rendered)
            console.log( result.content.rendered ); // ""
            setBlogDetails(result);
         });



         console.log("#########################");
         //console.log(JSON.stringify(postInfo));
       };
   
     
       
      useEffect(() => 
      {
         const jsonData = {
            "blogDetails": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        //fetchPosts(1);
        //handleClick();
       // fetchPosts(ITEMS_PER_PAGE,'1');
      // var blogId = urlObj.blogId;
      // alert(blogId);
       fetchPostDetail(blogId);
          
      },[]);  

   return(<>
   <main  class="empr-directory-main">
       <div class="main-bgpatten"></div>
          <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                  <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                        <li class="breadcrumb-item headtitle "><a href="/blog">Blog List</a> </li>
                          <li class="breadcrumb-item headtitle "><a href="#">Blog Details</a></li>
                         
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
       

       



         <section class="info-typography py-5">
            <div class="container">


          



               <div class="row">
                  <div class="col-lg-8">
                     <div class="py-5 px-5 mb-4 white-bg" style={{"padding-bottom":"20px !important;"}}>
                       <div class="row">

                       <div id="primary">
                            <div class="clear">
                                    
                                     <h1 class="page-title">{blogTitle}</h1> 
                                    <p>
                                   
                                        <div dangerouslySetInnerHTML={{ __html:  blogDescription  }} />
                                    </p>
                                        </div>
                        </div>


                      
                        
                       </div>



                     

                     
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <Recent_Jobs/>
                     {/* <div class="white-bg mb-4">
                        
                     <img src="/images/ad-1.png" class="w-100" alt="" />
                     </div>
                     <div class="white-bg mb-4">
                        <img src="/images/ad-2.png" class="w-100" alt="" />
                     </div> */}
                    {/* <div class="p-4 white-bg mb-4">
                        <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                        <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                        <img src="images/HTO-app-face.png" class="w-100" alt="" />

                        <div class="d-flex justify-content-center  mt-3">
                           <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                           <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width":"130px;"}} />
                        </div>
                    </div>*/}
                    
                  </div>
               </div>
            </div>
            <Hiring/>
         </section>
         </main> 
   </>);
 
}

BlogDetails.propTypes = {};

BlogDetails.defaultProps = {};

export default BlogDetails;