import React,{history,useEffect, useState, useMemo } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import Stay_Connected from "../home/Stay_Connected";
import Recent_Jobs from "../Jobs/Recent_Jobs";
import Hiring from "../Jobs/Hiring";
import Pagination3 from "../../utility/Pagination/Pagination";
import WPAPI from "wpapi";
import moment from "moment";

const Blog=()=>
{
    const [articles, setArticles] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);

    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination

    const wp = new WPAPI({
        endpoint: "https://snv3cms.smallnation.site/wp-json"
      });
    

      const handlePageChange3 = (offset,triggerevent) => {
         fetchPosts(ITEMS_PER_PAGE,offset)
       };


      const handleClick = async () => {
        const snapshot = await fetchPosts(pageNumber);
    
        setPageNumber(snapshot.nextPageNumber);
        setArticles([
          // Preserve previous WordPress posts
          ...articles,
    
          // Add new WordPress posts
          ...snapshot.posts
        ]);
      };
      const fetchPosts = async (ITEMS_PER_PAGE,pageNumber?: number) => {
        const request = wp.posts();
      
        if (pageNumber) {
          request.page(pageNumber);
        }
      
        //const posts = await request.get();
       // const posts = await wp.posts().perPage(ITEMS_PER_PAGE).get();
       const posts = await wp.posts().perPage(ITEMS_PER_PAGE).page(pageNumber);
        setArticles(posts);

       // setTotalItems(posts._paging.totalPages);         
         //settotalPages(Math.ceil(posts._paging.totalPages / ITEMS_PER_PAGE));                    
         setCurrentPage(pageNumber);
      
        return {
          posts,
          nextPageNumber:
            posts._paging.totalPages > pageNumber ? pageNumber + 1 : null
        };
      };
       
      useEffect(() => 
      {
         const jsonData = {
            "blog": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        //fetchPosts(1);
        //handleClick();
        $.get( 'https://snv3cms.smallnation.site/wp-json/wp/v2/posts', function( data, status, request ) {
         var numPosts = request.getResponseHeader('x-wp-total');
         setTotalItems(numPosts);
         settotalPages(Math.ceil(numPosts/ ITEMS_PER_PAGE));             
         console.log( numPosts ); //outputs number of posts to console
         });
        fetchPosts(ITEMS_PER_PAGE,'1');
          
      },[]);  

   return(<>
   <main  class="empr-directory-main">
       <div class="main-bgpatten"></div>
          <section class="headtitle-wrap">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                  <nav style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item headtitle "><a href="#">Blog </a></li>
                         
                        </ol>
                      </nav>
                  </div>
               </div>
            </div>
         </section>
       

       



         <section class="info-typography py-5">
            <div class="container">


          



               <div class="row">
                  <div class="col-lg-8">
                     <div class="py-5 px-5 mb-4 white-bg" style={{"padding-bottom":"20px !important;"}}>
                       <div class="row">

                       {articles.map((article) => (

                        <div class="oppertunity-card">
                            <div class="logo-org">
                                {/* <img src="images/wsu-biplane-logo.png" alt="" /> */}
                                <img src="images/no-img.jpg" alt="" />

                                
                            </div>
                            <div class="right-info">
                               <a href={"/blogDetails?blogId="+article.id} key={article.id}> <h4>{article.title.rendered}</h4></a>
                                 <span class="text-uppercase">{moment(article.date).format("MM/DD/YYYY")}</span> 
                                
                                {/* <p class="mt-2">Wright State University Lake Campus in Celina is proud to announce the starting of the 15th MBA program. This program…</p> */}
                                {/* <p class="mt-2" dangerouslySetInnerHTML={{ __html: article.content.rendered.substring(0, 300)  }} /> */}
                                <p class="mt-2" dangerouslySetInnerHTML={{ __html: article.excerpt.rendered.substring(0, 300)  }} />
                            </div>
                        </div>
                         ))}


                        {/* {pageNumber && (
                            <button onClick={handleClick} class="reg-button">
                            {pageNumber > 1 ? "Load more" : "Get initial posts"}
                            </button>
                        )} */}
                        
                        
                       </div>



                       <div class="pagination-wrap text-center mt-3 paginationContent">
                                    <Pagination3               
                              total={totalItems}
                              itemsPerPage={ITEMS_PER_PAGE}
                              currentPage={currentPage}
                              onPageChange={handlePageChange3}/>
                                          
                     </div>


                        {/* <div class="pagination-wrap text-center mt-3">
                           <nav aria-label="...">
                              <ul class="pagination m-auto d-inline-flex">
                                 <li class="page-item disabled">
                                    <span class="page-link"><img src="images/arrow-left.JPG" alt="" /></span>
                                 </li>
                                 <li class="page-item"><a class="page-link" href="#">1</a></li>
                                 <li class="page-item active" aria-current="page">
                                    <span class="page-link">2</span>
                                 </li>
                                 <li class="page-item"><a class="page-link" href="#">3</a></li>
                                 <li class="page-item">
                                    <a class="page-link" href="#"><img src="images/arrow-right.JPG" alt="" /></a>
                                 </li>
                              </ul>
                           </nav>
                        </div> */}
                     </div>
                  </div>
                  <div class="col-lg-4">
                     <Recent_Jobs/>
                     {/* <div class="white-bg mb-4">
                        
                        <img src="images/ad-1.png" class="w-100" alt="" />
                     </div>
                     <div class="white-bg mb-4">
                        <img src="images/ad-2.png" class="w-100" alt="" />
                     </div> */}
                     {/* <div class="p-4 white-bg mb-4">
                        <h2 class="title-stay-connect">Stay connected. Get the app!</h2>
                        <p>Download the Hometown Opportunity app and stay constanly connected with companies and job oppportunities in your area. Hometown Opportunity is available on the Apple App Store or on Google Play.</p>
                        <img src="images/HTO-app-face.png" class="w-100" alt="" />

                        <div class="d-flex justify-content-center  mt-3">
                           <img src="images/apple-app-store.png" alt="" style={{"width":" 130px;"}} />
                           <img src="images/google-play.png" alt="" class="ms-2" style={{"max-width":"130px;"}} />
                        </div>
                    </div> */}
                    
                  </div>
               </div>
            </div>
            <Hiring/>
         </section>
         </main> 
   </>);
 
}

Blog.propTypes = {};

Blog.defaultProps = {};

export default Blog;