import React, { history, useEffect, useState, useMemo } from "react";
import Services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate } from 'react-router-dom';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import Modal from 'react-modal';
const PostJob = () => {
  const navigate = useNavigate();
  const [categories, setCategory] = useState([]);
  const [jobTypes, setJobType] = useState([]);
  const [jobSummary, setJobSummary] = useState('');
  const [jobExperience, setJobExperience] = useState('');
  const [jobSalary, setJobSalary] = useState('');

  const [educations, setEducation] = useState([]);
  const [locationCounty, setLocationCounty] = useState([]);
  const [countListAr, setCountListAr] = useState([]);

  const companyId = sessionStorage.getItem("logCompanyId");
  const companyIdChkLogin = sessionStorage.getItem("logCompanyId");
  const emailLogin = sessionStorage.getItem("logEmail");
  const userIdLogin = sessionStorage.getItem("logUserId");

  //get animated components wrapper
  const animatedComponents = makeAnimated();

  //set default query terms
  const [query, setQuery] = useState("");
  let jobTitleGlobal = '';

  // fetch filteres search results for dropdown
  /*const loadOptions = () => {
    return fetch(`https://wagesurvey.smallnation.site/api/jobSugList?title=${query}`)
      .then((res) => res.json());
  }; */

  const loadOptions = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
      },
      body: JSON.stringify({ title: query }), // Convert data to JSON format
    };
   
    return fetch('https://wagesurvey.smallnation.site/api/jobSugList', requestOptions)
      .then((res) => res.json());
  };

  const handleChange = (selectedOption) => {
    //alert(JSON.stringify(selectedOption));
    var title = selectedOption.title;
    jobTitleGlobal = selectedOption.title;
    setJobCode(title);
    setSocCodeTitle(title);
    setSocCode(selectedOption.socCode);
    setTimeout(function () { getWageInfoCall(); }, 2000);
  }

  const [countyDisplayName, setCountyDisplayName] = useState('');
  const [collab, setCollabs] = useState('');
  const [socCode, setSocCode] = useState('');
  const [socCodeTitle, setSocCodeTitle] = useState('');
  const [jobCodeTitle, setJobCode] = useState('');
  const [wageList, setWageListObj] = useState('');
  const [jobCodeTitleInput, setJobCodeTitleInput] = useState('');


  const [dolWageLevel1, setDolWageLevel1] = useState('');
  const [dolWageLevel2, setDolWageLevel2] = useState('');
  const [dolWageLevel3, setDolWageLevel3] = useState('');
  const [dolWageLevel4, setDolWageLevel4] = useState('');

  const [dolWageHourlyLevel1, setDolWageHourlyLevel1] = useState('');
  const [dolWageHourlyLevel2, setDolWageHourlyLevel2] = useState('');
  const [dolWageHourlyLevel3, setDolWageHourlyLevel3] = useState('');
  const [dolWageHourlyLevel4, setDolWageHourlyLevel4] = useState('');

  const [surveyWageLevel1, setsurveyWageLevel1] = useState('');
  const [surveyWageLevel2, setsurveyWageLevel2] = useState('');
  const [surveyWageLevel3, setsurveyWageLevel3] = useState('');
  const [surveyWageLevel4, setsurveyWageLevel4] = useState('');

  const [surveyWageHourlyLevel1, setsurveyWageHourlyLevel1] = useState('');
  const [surveyWageHourlyLevel2, setsurveyWageHourlyLevel2] = useState('');
  const [surveyWageHourlyLevel3, setsurveyWageHourlyLevel3] = useState('');
  const [surveyWageHourlyLevel4, setsurveyWageHourlyLevel4] = useState('');


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function handleChangeJobSummaryDesc(content) {
    setJobSummary(content);
  }
  
  
function handleChangeJobExperienceDesc(content) {
    setJobExperience(content);
  }
  
  function handleChangeJobSalaryDesc(content) {
    setJobSalary(content);
  }

  function handleImageUploadBefore(files, info, uploadHandler) {
    var reader1 = new FileReader();
    reader1.readAsDataURL(files[0]);
    reader1.onload = function () {
      var rdt1 = reader1.result;
      var base64result_banner = rdt1.split(',')[1];
      var bname = files[0].name;
      var bextn = bname.split(".").pop();
      var bannerActName = bname.split(".")[0];
      var fileext_banner = bextn;

      const requestObject = {
        //"imageName": files[0].name,
        "imageName": bannerActName,
        "imageExtension": fileext_banner,
        "imageByteString": base64result_banner
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'Authorization': "Bearer "+accessTokenIn 
          //'Authorization': accessTokenIn 
        },
        body: JSON.stringify(requestObject)
      };

      Services.fileUploadS3URL(requestOptions).then((res) => {
        const response = {
          // The response must have a "result" array.
          "result": [
            {
              "url": res.url,
              "temp": files[0],
              "name": bannerActName,
              "size": files[0].size
            },
          ]
        }
        uploadHandler(response);

      });
    };

  }

  function openModal() {
    setIsOpen(true);
    $("#junior_hcheck").hide();
    $("#midlevel_hcheck").hide();
    $("#manager_hcheck").hide();
    $("#excutive_hcheck").hide();
    $("#soc_code_check").hide();
    $("#junior_ycheck").hide();
    $("#midlevel_ycheck").hide();
    $("#manager_ycheck").hide();
    $("#excutive_ycheck").hide();
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';

    $("#junior_hcheck").hide();
    $("#midlevel_hcheck").hide();
    $("#manager_hcheck").hide();
    $("#excutive_hcheck").hide();
    $("#soc_code_check").hide();
    $("#junior_ycheck").hide();
    $("#midlevel_ycheck").hide();
    $("#manager_ycheck").hide();
    $("#excutive_ycheck").hide();
    $("#successMessageWageSurvey").hide();
    $(".allow_numeric3").keyup(function () {

      var ex = /^\d{0,3}(\.\d{0,2})?$/;
      if (ex.test(this.value) == false) {
        this.value = this.value.substring(0, this.value.length - 1);
      }

    });

    $(".allow_numericd").keyup(function () {
      //var ex = /^[0-9]+\.?[0-3]*$/;
      var ex = /^\d{0,7}(\.\d{0,2})?$/;
      if (ex.test(this.value) == false) {
        this.value = this.value.substring(0, this.value.length - 1);
      }
    });
  }

  function validateJunior_hD() {
    var i_val = "";
       var dValue = $('#junior_h1'+i_val).val();
       if(dValue != ""){
         $('#junior_hcheck'+i_val).hide();
       return true;
       }else{
         $('#junior_hcheck'+i_val).css("color", "red").html("*Level 1 hourly field is required");
        $('#junior_hcheck'+i_val).show();
       return false;
       }
   }

    function validateJunior_yD() {
     var i_val = "";
       var dValue = $('#junior_y1'+i_val).val();
       if(dValue != ""){
         $('#junior_ycheck'+i_val).hide();
       return true;
       }else{
         $('#junior_ycheck'+i_val).css("color", "red").html("*Level 1 yearly field is required");
        $('#junior_ycheck'+i_val).show();
       return false;
       }
   }

    function validatemidlevel_hD() {
     var i_val = "";
       var dValue = $('#midlevel_h1'+i_val).val();
       if(dValue != ""){
         $('#midlevel_hcheck'+i_val).hide();
       return true;
       }else{
         $('#midlevel_hcheck'+i_val).css("color", "red").html("*Level 2 hourly field is required");
        $('#midlevel_hcheck'+i_val).show();
       return false;
       }
   }

    function validatemidlevel_yD() {
     var i_val = "";
       var dValue = $('#midlevel_y1'+i_val).val();
       if(dValue != ""){
         $('#midlevel_ycheck'+i_val).hide();
       return true;
       }else{
         $('#midlevel_ycheck'+i_val).css("color", "red").html("*Level 2 yearly field is required");
        $('#midlevel_ycheck'+i_val).show();
       return false;
       }
   }

    function validatemanager_hD() {
     var i_val = "";
       var dValue = $('#manager_h1'+i_val).val();
       if(dValue != ""){
         $('#manager_hcheck'+i_val).hide();
       return true;
       }else{
         $('#manager_hcheck'+i_val).css("color", "red").html("*Level 3 hourly field is required");
        $('#manager_hcheck'+i_val).show();
       return false;
       }
   }

    function validatemanager_yD() {
     var i_val = "";
       var dValue = $('#manager_y1'+i_val).val();
       if(dValue != ""){
         $('#manager_ycheck'+i_val).hide();
       return true;
       }else{
         $('#manager_ycheck'+i_val).css("color", "red").html("*Level 3 yearly field is required");
        $('#manager_ycheck'+i_val).show();
       return false;
       }
   }

    function validateexcutive_hD() {
     var i_val = "";
       var dValue = $('#excutive_h1'+i_val).val();
       if(dValue != ""){
         $('#excutive_hcheck'+i_val).hide();
       return true;
       }else{
         $('#excutive_hcheck'+i_val).css("color", "red").html("*Level 4 hourly field is required");
        $('#excutive_hcheck'+i_val).show();
       return false;
       }
   }

    function validateexcutive_yD() {
     var i_val = "";
       var dValue = $('#excutive_y1'+i_val).val();
       if(dValue != ""){
       $('#excutive_ycheck'+i_val).hide();
       return true;
       }else{
         $('#excutive_ycheck'+i_val).css("color", "red").html("*Level 4 yearly field is required");
        $('#excutive_ycheck'+i_val).show();
       return false;
       }
   }
  function submitWageData() {
    //////////////////

   /*
    if ($('#stateId').val() == '') {
      $('#statecheck').show();
      $(window).scrollTop($('#statecheck').position().top);
      return false;
    }
    else {
      $('#statecheck').hide();
    }

    if ($('#countyId').val() == '') {
      $('#countycheck').show();
      $(window).scrollTop($('#countycheck').position().top);
      return false;
    } else {
      $('#countycheck').hide();
    }

    if ($('#cityId').val() == '') {
      $('#citycheck').show();
      $(window).scrollTop($('#citycheck').position().top);
      return false;
    } else {
      $('#citycheck').hide();
    }
    */

    var validateStateV = true;
    var validateCountyV = true;
    var validateCityV = true;
    var validateTitleDV = true;
    var validateCodeDV = true;



    var junior_h1 = $("#junior_h1").val();
    var midlevel_h1 = $("#midlevel_h1").val();
    var manager_h1 = $("#manager_h1").val();
    var excutive_h1 = $("#excutive_h1").val();

    var junior_y1 = $("#junior_y1").val();
    var midlevel_y1 = $("#midlevel_y1").val();
    var manager_y1 = $("#manager_y1").val();
    var excutive_y1 = $("#excutive_y1").val();

    var hState = 0;
    var yState = 0;
    var i_val = "";
    if (junior_h1 != "" && midlevel_h1 != "" && manager_h1 != "" && excutive_h1 != "") {
      hState = 1;

      validateJunior_hD();
      validatemidlevel_hD();
      validatemanager_hD();
      validateexcutive_hD();

      $('#junior_ycheck' + i_val).hide();
      $('#midlevel_ycheck' + i_val).hide();
      $('#manager_ycheck' + i_val).hide();
      $('#excutive_ycheck' + i_val).hide();
    }

    if (junior_y1 != "" && midlevel_y1 != "" && manager_y1 != "" && excutive_y1 != "") {
      yState = 1;

      validateJunior_yD();
      validatemidlevel_yD();
      validatemanager_yD();
      validateexcutive_yD();

      $('#junior_hcheck' + i_val).hide();
      $('#midlevel_hcheck' + i_val).hide();
      $('#manager_hcheck' + i_val).hide();
      $('#excutive_hcheck' + i_val).hide();
    }

    if (hState == "0" && yState == "0") {

      var validateJunior_hDV = validateJunior_hD();
      var validatemidlevel_hDV = validatemidlevel_hD();
      var validatemanager_hDV = validatemanager_hD();
      var validateexcutive_hDV = validateexcutive_hD();

      var validateJunior_yDV = validateJunior_yD();
      var validatemidlevel_yDV = validatemidlevel_yD();
      var validatemanager_yDV = validatemanager_yD();
      var validateexcutive_yDV = validateexcutive_yD();

    } else {
      var validateJunior_hDV = true;
      var validatemidlevel_hDV = true;
      var validatemanager_hDV = true;
      var validateexcutive_hDV = true;
      var validateJunior_yDV = true;
      var validatemidlevel_yDV = true;
      var validatemanager_yDV = true;
      var validateexcutive_yDV = true;
    }

    if (!validateJunior_hDV || !validateJunior_yDV || (!validatemidlevel_hDV || !validatemidlevel_yDV) || !validatemanager_hDV || !validatemanager_yDV || !validateexcutive_hDV || !validateexcutive_yDV) {
      //alert("Please fill all the required fields");
      return false;
    }

    var job_code = $("#jobCodeTitle").html();
    var soc_code = $("#socCodeVal").html();
    var state_code = 'OH';

    var countyId = $("#countyId").val();
    //alert(countyId);
    // alert(JSON.stringify(countListAr));
    //alert( countListAr[countyId]);
    /*
    if (countyId == "") {
      var county = 'Crawford County';
    } 
    else {
      var county = countListAr[countyId] + ' County';
    }
    */

    var county = countyDisplayName;
    if (county == "") {
       county = 'Crawford County';
    } 

    const requestObject =
    {
      "sourceDomain": "hometownopportunity.com",
      // "emailAddress": "xyz@gmail.com",
      "emailAddress": emailLogin,
      "uid": userIdLogin,
      "companyIdRef": companyIdChkLogin,
      "wageArr": [{
        //"state": "DC",
        "state": state_code,
        //"city": "hyderabad",
        "city": $("#cityId").val(),
        "county": county,
        //"county": "District of Columbia",
        //"job_title": "abc",
        //"job_code": "Print Binding and Finishing Workers",
        "job_title": jobCodeTitle,
        "job_code": job_code,

        //"socCode": "51-5113",
        "socCode": soc_code,
        "junior_h": junior_h1,
        "junior_y": junior_y1,
        "midlevel_h": midlevel_h1,
        "midlevel_y": midlevel_y1,
        "manager_h": manager_h1,
        "manager_y": manager_y1,
        "excutive_h": excutive_h1,
        "excutive_y": excutive_y1,
      }]
    }



    console.log(requestObject);
    console.log(JSON.stringify(requestObject));


    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.postWageSurvey(requestOptions).then((res) => {


      console.log(res);
      $("#successMessageWageSurvey").show();
      $(".load-wrap").hide();
      //navigate('/companyJobs');


    });
    
  }

  function closeModal() {
    setIsOpen(false);
  }

  function applylink() {
    const applycheck = $('input[name="companyportal_consent"]:checked').length;
    if (applycheck > 0) {
      $('#portallinkdiv').show();
    }
    else {
      $('#portal_link').val('');
      $('#portallinkdiv').hide();
    }
  }

  useEffect(() => {
    const jsonData = {
      "postJob": {
        
      }
    };

  var jsondataformatted = JSON.stringify(jsonData);
  sessionStorage.setItem('pagejsonObject', jsondataformatted);

    $(".se-dialog-tabs").hide(); 
    $("#junior_hcheck").hide();
    $("#midlevel_hcheck").hide();
    $("#manager_hcheck").hide();
    $("#excutive_hcheck").hide();
    $("#soc_code_check").hide();
    $("#junior_ycheck").hide();
    $("#midlevel_ycheck").hide();
    $("#manager_ycheck").hide();
    $("#excutive_ycheck").hide();
    $(".load-wrap").hide();
    $("#wageDataButton").hide();

    $(".allow_numeric3").keyup(function () {

      var ex = /^\d{0,3}(\.\d{0,2})?$/;
      if (ex.test(this.value) == false) {
        this.value = this.value.substring(0, this.value.length - 1);
      }

    });

    $(".allow_numericd").keyup(function () {
      //var ex = /^[0-9]+\.?[0-3]*$/;
      var ex = /^\d{0,7}(\.\d{0,2})?$/;
      if (ex.test(this.value) == false) {
        this.value = this.value.substring(0, this.value.length - 1);
      }
    });



    if (companyId == null || companyId == "null") {
      //navigate('/login');
      //navigate('/Home');
      window.location.href = "/Home?loginRedirect=1";
    }
    $('#countyValidMsg').hide();
    $('#portalLinkValidregex').hide();
    $('#portallinkdiv').hide();
    $("#jobSummaryValidMsg").hide();
    $("#jobExperienceValidMsg").hide();
    $("#jobSalaryValidMsg").hide();
    $("#successMessage").hide();
    getIndustryData();
    getJobType();
    getEducation();

    getLocationCounty();
    /*
    $( "#countyId" ).change(function() {
      getWageInfoCall();
    });
    */


    $("#title").change(function () {
      var st = $("#title").val();
      setJobCodeTitleInput(st);
    });



  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all", mode: "onBlur"
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      maxWidth: '1000px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxheight:'600px',


    },
  };

  function validateCheck() {
    const checked = $('input[name="category"]:checked').length;

    if (checked >= 3) {
      $('input[name="category"]').not(':checked').prop('disabled', true);
    } else {
      $('input[name="category"]').prop('disabled', false);
    }
  }


  const getWageInfoCall = () => {
    //var state = $("#stateId").val();
    //var county = $("#countyId").val();
    var job_code = $("#jobCodeTitle").html();
    var soc_code = $("#socCodeVal").html();

    var state = 'OH';
    //var county = 'Crawford County';
    var countyId = $("#countyId").val();
    //alert(countyId);
    // alert(JSON.stringify(countListAr));
    //alert( countListAr[countyId]);
    if (countyId == "") {
      var county = 'Crawford County';
    } else {
      var county = countListAr[countyId] + ' County';
    }


    county = county == "" ? 'Crawford County' : county;
    //alert(county);
    setCountyDisplayName(county)

    //alert(socCodeTitle);
    // alert(job_code);
    const requestObject = {
      "stateCode": state,
      "county": county,
      //"job_code": jobCode,
      "job_code": job_code,
      "soccode": soc_code,
      //"soccode": socCode,

    }
    // console.log(JSON.stringify(objInputAvailableJobs));
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'Authorization': "Bearer "+accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $("#wageDataButton").show();
    Services.getSurveyWageList(requestOptions).then((res) => {
      console.log(JSON.stringify(res))
      if (res) {
        setWageListObj(res);
        setDolWageLevel1('');
        setDolWageLevel2('');
        setDolWageLevel3('');
        setDolWageLevel4('');

        setDolWageHourlyLevel1('');
        setDolWageHourlyLevel2('');
        setDolWageHourlyLevel3('');
        setDolWageHourlyLevel4('');

        setsurveyWageLevel1('');
        setsurveyWageLevel2('');
        setsurveyWageLevel3('');
        setsurveyWageLevel4('');

        setsurveyWageHourlyLevel1('');
        setsurveyWageHourlyLevel2('');
        setsurveyWageHourlyLevel3('');
        setsurveyWageHourlyLevel4('');
        try {
          if (typeof (res.dolWage[0].level1) != "undefined" && res.dolWage[0].level1 != null) {
            // alert(res.dolWage[0].level1);
            setDolWageLevel1(res.dolWage[0].level1);
            setDolWageLevel2(res.dolWage[0].level2);
            setDolWageLevel3(res.dolWage[0].level3);
            setDolWageLevel4(res.dolWage[0].level4);

          }
        } catch (ex) {

        }


        try {
          if (typeof (res.dolWageHourly[0].level1) != "undefined" && res.dolWageHourly[0].level1 != null) {

            setDolWageHourlyLevel1(res.dolWageHourly[0].level1);
            setDolWageHourlyLevel2(res.dolWageHourly[0].level2);
            setDolWageHourlyLevel3(res.dolWageHourly[0].level3);
            setDolWageHourlyLevel4(res.dolWageHourly[0].level4);
          }
        } catch (ex) {

        }



        try {
          if (typeof (res.surveyWage[0].level1) != "undefined" && res.surveyWage[0].level1 != null) {
            setsurveyWageLevel1(res.surveyWage[0].level1);
            setsurveyWageLevel2(res.surveyWage[0].level2);
            setsurveyWageLevel3(res.surveyWage[0].level3);
            setsurveyWageLevel4(res.surveyWage[0].level4);
          }
        } catch (ex) {

        }



        try {
          if (typeof (res.surveyWageHourly[0].level1) != "undefined" && res.surveyWageHourly[0].level1 != null) {
            setsurveyWageHourlyLevel1(res.surveyWageHourly[0].level1);
            setsurveyWageHourlyLevel2(res.surveyWageHourly[0].level2);
            setsurveyWageHourlyLevel3(res.surveyWageHourly[0].level3);
            setsurveyWageHourlyLevel4(res.surveyWageHourly[0].level4);
          }
        } catch (ex) {

        }


      }

    });



  }


  const submitValue = () => {
    var website_regex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    //var website_regex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    var categoriesArray = [];
    $.each($("input[name='category']:checked"), function () {
      categoriesArray.push($(this).val());
    });
    var validateEditorTxt = 1;
    if ($("#countyId").val() == '') {
      $("#countyValidMsg").show();
      $(window).scrollTop($('#countyValidMsg').position().top);
      validateEditorTxt = 0;
    }

    if (jobSummary == "") {
      $("#jobSummaryValidMsg").show();
      // $("#jobSummaryValidMsg").focus();
      $(window).scrollTop($('#jobSummaryValidMsg').position().top);
      validateEditorTxt = 0;

    }

    var portallink = $('#portal_link').val();
    var portalvalid = website_regex.test(portallink);
    if (portallink != '') {
      console.log(portalvalid);
      if (portalvalid == true) {
        console.log('true val');
        $("#portalLinkValidregex").hide();
      }
      else {
        console.log('false val');
        $("#portalLinkValidregex").show();
        $(window).scrollTop($('#portalLinkValidregex').position().top);
        validateEditorTxt = 0;
      }
    }
    else {
      $("#portalLinkValidregex").hide();
    }
    /*  if (portallink != "" && website_regex.test(portallink) == false) {
        $("#portalLinkValidregex").show();
        $(window).scrollTop($('#portalLinkValidregex').position().top);
        validateEditorTxt = 0;
      } else {
        console.log('here');
        $("#portalLinkValidregex").hide();
      } */

    if (jobExperience == "") {
      $("#jobExperienceValidMsg").show();
      //$("#jobExperienceValidMsg").focus();
      $(window).scrollTop($('#jobExperienceValidMsg').position().top);
      validateEditorTxt = 0;

    }

    if (jobSalary == "") {
      $("#jobSalaryValidMsg").show();
      //$("#jobSalaryValidMsg").focus();
      $(window).scrollTop($('#jobSalaryValidMsg').position().top);
      validateEditorTxt = 0;

    }
    if (validateEditorTxt == 0) {
      return false;
    }

    console.log(JSON.stringify(categoriesArray));

    const requestObject =
    {
      "siteId": process.env.React_App_SITE_ID,
      "companyId": companyId,
      "countyId": $("#countyId").val(),
      "jobTypeId": $("#jobTypeId").val(),
      "educationId": $("#educationId").val(),
      //"posted" : $("#cname").val(),
      "title": $("#title").val(),
      //"summary" : $("#summary").val(),
      "summary": jobSummary,
      //"experiance" : $("#experiance").val(),
      "experiance": jobExperience,
      "career": $("#career").val(),
      "apply": $("#apply").val(),
      "jobApplyUrl": $('#portal_link').val(),
      // "retail" : $("#cname").val(),
      "reference": $("#reference").val(),
      //"salary" : $("#salary").val(),
      "salary": jobSalary,
      // "status" : $("#cname").val(),
      //"updatedOn" : $("#cname").val(),
      //"jobCategory":categoriesArray,
      "jobCategoryRelId": categoriesArray,

    }
    console.log(requestObject);
    var accessTokenIn = sessionStorage.getItem("accToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + accessTokenIn
        //'Authorization': accessTokenIn
      },
      body: JSON.stringify(requestObject)
    };
    $(".load-wrap").show();
    Services.postJob(requestOptions).then((res) => {


      $("#successMessage").fadeTo(2000, 500).slideUp(500, function () {
        $(".load-wrap").hide();
        $("#successMessage").slideUp(500);
      });
      // navigate('/companyJobs');
      setTimeout(function () { navigate('/companyJobs'); }, 2000);

    });


  }

  const getIndustryData = () => {
    Services.getIndustryData().then((res) => {
      console.log("Industry data=" + JSON.stringify(res.data));
      setCategory(res.data.content);
    });
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const getJobType = () => {
    /* Services.getJobType().then((res) => {
       console.log("setJobType data=" + JSON.stringify(res.data));
       setJobType(res.data.content);
     }); */
    Services.getJobType().then((res) => {
      console.log("setJobType data=" + JSON.stringify(res.data));
      var jobtype_unsort = res.data.content;
      var jobtypes_sorted = jobtype_unsort.sort((a, b) => b.jobTypeId - a.jobTypeId);
      array_move(jobtypes_sorted, 0, (jobtypes_sorted.length - 1));
      jobtypes_sorted = jobtypes_sorted.filter(function (elem) {
        return elem.name !== 'Continuing Education';
      });
      setJobType(jobtypes_sorted);
    });
  }

  const getEducation = () => {
    Services.getEducation().then((res) => {
      console.log("getEducation data=" + JSON.stringify(res.data));
      setEducation(res.data.content);
    });
  }

  const changeCountyTrigger = () => {
    //alert('400');
    getWageInfoCall();
  }
  const getLocationCounty = () => {
    Services.getLocationCounty().then((res) => {
      console.log("getLocationCounty data=" + JSON.stringify(res.data));
      var countyArr = res.data.content;
      var countListArTemp = {};
      //alert(JSON.stringify(countyArr))
      for (var j = 0; j < countyArr.length; j++) {
        var atval = countyArr[j]['jobLocationCountyId'];
        countListArTemp[atval] = countyArr[j].name;
        //alert(JSON.stringify(countListArTemp))
      }
      //alert(JSON.stringify(countLdirectoryistArTemp))
      setCountListAr(countListArTemp);
      setLocationCounty(res.data.content);
      /*
      $( "#countyId" ).change(function() {
         setCountListAr(countListArTemp);
        getWageInfoCall();
      });
      */
    });
  }

  return (
    <>
      <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        <section class="headtitle-wrap">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">

                <nav style={{ "--bs-breadcrumb-divider": "'>';" }} aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item headtitle"> <a href="/companyJobs">Company Dashboard</a> </li>
                    <li class="breadcrumb-item headtitle "><a href="#">Post a Job </a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- info typography --> */}
        <section class="company-detail-wrap py-5 ">
          <form onSubmit={handleSubmit(submitValue)}>
            <div class="container">
              <div class="row">
                <div class="col-12 ">
                  <div class="white-bg px-5 pt-4 pb-4">

                    {/* <div class="row">
                          <div class="col-lg-12 m-auto">
                                <h1 class="text-center ">
                                   Post Job
                                </h1>
                                <div class="col-3 m-auto">
                                    <hr/>
                                </div>
                          </div>

                      </div> */}

                    <div class="row mt-4 resume-wrap">
                      <div class="col-lg-12 mb-4">
                        <label for="firstName" class="form-label">Job Title <span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="title" name="title" placeholder="" {...register('title', { required: true })} />
                        {errors.title && errors.title.type === "required" && <div class="error">Title is required</div>}
                      </div>



                      <div class="col-lg-12 mb-4">
                        <label for="firstName" class="form-label">Standardized job code</label>
                        {/* <input type="text"  class="form-control" id="stjobcode"  defaultValue=""  {...register('stjobcode', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/> */}


                        <AsyncSelect
                          cacheOptions
                          components={animatedComponents}
                          getOptionLabel={(e) => e.title}
                          getOptionValue={(e) => e.socCode}
                          loadOptions={loadOptions}
                          onInputChange={(value) => setQuery(value)}
                          onChange={handleChange}
                        // onChange={(value) => setSocCode(value)}
                        />
                      </div>

                      <div class="col-lg-12 mb-4">
                        <label for="phone" class="form-label">Soc Code:</label>
                        <span id="socCodeVal">{socCode}</span>
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">Job Type  <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" name="jobTypeId" id="jobTypeId" {...register('jobTypeId', { required: true })}>
                          <option value='' selected>Select</option>
                          {/* <option value="1">One</option>
                               <option value="2">Two</option>
                               <option value="3">Three</option> */}
                          {
                            jobTypes.map((data, index) => (
                              <option value={data.jobTypeId}>{data.name}</option>
                            ))}
                        </select>
                        {errors.jobTypeId && errors.jobTypeId.type === "required" && <div class="error">Job Type is required</div>}
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label"> Education <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" name="educationId" id="educationId" {...register('educationId', { required: true })}>
                          <option value='' selected>Select</option>
                          {/* <option value="1">One</option>
                               <option value="2">Two</option>
                               <option value="3">Three</option> */}
                          {
                            educations.map((data, index) => (
                              <option value={data.educationId}>{data.name}</option>
                            ))}
                        </select>
                        {errors.educationId && errors.educationId.type === "required" && <div class="error">Education is required</div>}
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label"> Job Is Located In Which County? <span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" onChange={changeCountyTrigger} name="countyId" id="countyId">
                          <option value='' selected>Select</option>
                          {/* <option value="1">One</option>
                               <option value="2">Two</option>
                               <option value="3">Three</option> */}
                          {
                            locationCounty.map((data, index) => (
                              <option value={data.jobLocationCountyId} >{data.name}</option>
                            ))}
                        </select>
                        <div id="countyValidMsg" class="error">County is required.</div>
                      </div>

                      <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">Career Level<span class="red-text">*</span></label>
                        <select class="form-select" aria-label="Default select example" name="career" id="career" {...register('career', { required: true })}>
                          <option selected value=''>Select</option>
                          <option value="Entry-Level">Entry-Level</option>
                          <option value="Mid-Level">Mid-Level</option>
                          <option value="Management/Supervisor">Management/Supervisor</option>
                          <option value="Executive Level">Executive Level</option>
                        </select>
                        {errors.career && errors.career.type === "required" && <div class="error">Career level is required</div>}
                      </div>

                      <div class="col-lg-6 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label">Additional Instructions to Apply</label></div>
                        <p style={{ fontSize: "12px" }}>Max 250 Characters</p>
                        <input type="text" class="form-control" id="apply" name="apply" placeholder="" maxLength={250}/>
                      </div>


                      {/* <div class="col-lg-6 mb-4">
                        <label for="firstName" class="form-label">How to Apply<span class="red-text">*</span></label>
                        <input type="text" class="form-control" id="apply" name="apply" placeholder="https://www.example.com" {...register("Apply", {
                                    required: "How to apply is required.",

                                    pattern: {
                                      value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                                      message: "invalid Website URL"
                                    }
                                    })} />
                         <ErrorMessage
                                    errors={errors}
                                    name="Apply"
                                    class="invalid-feedback"
                                    render={({ messages }) => {
                                    console.log("messages", messages);
                                    return messages
                                        ? Object.entries(messages).map(([type, message]) => (
                                            <p key={type}  class="error">{message}</p>
                                        ))
                                        : null;
                                    }}
                                />
                      </div> */}

                      <div class="col-lg-6 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Reference Code </label></div>
                        <input type="text" class="form-control" name="reference" id="reference" placeholder="" />

                      </div>

                      <div class="col-lg-12 mb-4">
                        <input class="form-check-input" type="checkbox" name="companyportal_consent" value="Y" id="companyportal_consent1" onClick={() => applylink()} />  <label class="form-check-label" for="defaultCheck1"> Check this box to receive applicants on your external website </label>
                        {/* Job seekers shall apply through company portal */}
                      </div>

                      <div class="col-lg-12 mb-4" id="portallinkdiv">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Link to job portal </label> <p style={{ fontSize: "12px" }}>Link Should start with http or https. (Ex: http://www.example.com)</p></div>
                        <input type="text" class="form-control" name="portal_link" id="portal_link" placeholder="" />

                        <div id="portalLinkValidregex" class="error">Please enter valid link.</div>
                      </div>

                      {/* <div class="col-lg-12 mb-4">
                            <div class="d-flex"><label for="firstName" class="form-label mr-2">Summary <span class="red-text">*</span></label> <small>Max 2000 Characters</small></div>
                            <textarea class="form-control textarea" placeholder="Leave a comment here" id="summary" name="summary" spellcheck="false" {...register('summary', { required: true,maxLength:2000 })}></textarea>
                            {errors.summary && errors.summary.type === "required" && <div class="error">Summary is required</div>}
                            {errors.summary && errors.summary.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}
                          </div> */}

                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Summary <span class="red-text">*</span></label></div>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          config={{
                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                          }}
                          id="summary"
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                            //editor.setData(profileDescription);
                            //editor.setData(profileDescription);

                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobSummary(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        /> */}

<SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeJobSummaryDesc}                              
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />

                        <div id="jobSummaryValidMsg" class="error">Summary is required.</div>
                      </div>


                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Experience/Certification Required <span class="red-text">*</span></label></div>

                        {/* <CKEditor
                          editor={ClassicEditor}
                          config={{
                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                          }}
                          id="experiance"
                          onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobExperience(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                          
                        /> */}

<SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeJobExperienceDesc}                              
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />

                        <p id="jobExperienceValidMsg" class="error">Experience/Certification is required.</p>


                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="experiance" name="experiance" spellcheck="false" {...register('experiance', { required: true, maxLength:2000 })}></textarea>
                           {errors.experiance && errors.experiance.type === "required" && <div class="error">Experience/Certification is required</div>}
                           {errors.experiance && errors.experiance.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}        */}
                      </div>

                      <div class="col-lg-12 mb-4">
                        <div>
                          <button onClick={openModal} class="reg-button mb-5" id="wageDataButton">WAGE DATA</button>
                          <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                          >
                            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>WAGE DATA</h2>
                            <button onClick={closeModal} class="wage-close">close</button>

                              <div className="wd-modal-auto">
                                <table class="table wage-table" >
                                    <tr>
                                      <td>
                                        <b>Location: </b> <span>OH, {countyDisplayName}</span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <b>Job Title: </b> <span>{jobCodeTitleInput}</span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <b>Standardized Job Title: </b> <span id="jobCodeTitle">{jobCodeTitle}</span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <b>SOC CODE: </b> <span >{socCode}</span>
                                      </td>
                                    </tr>


                                  </table>


                                  <table class="table wage-data-tb" >
                                    <thead class="thead-light">
                                      <tr >
                                        <th scope="col">SOURCE</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">LEVEL-1</th>
                                        <th scope="col">LEVEL-2</th>
                                        <th scope="col">LEVEL-3</th>
                                        <th scope="col">LEVEL-4</th>


                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>

                                        <th scope="row" rowspan="2">DOL Wage Data</th>
                                        <td id="">Yearly</td>
                                        <td id="dol_level1">{dolWageLevel1}</td>
                                        <td id="dol_level2">{dolWageLevel2}</td>
                                        <td id="dol_level3">{dolWageLevel3}</td>
                                        <td id="dol_level4">{dolWageLevel4}</td>

                                      </tr>

                                      <tr>
                                        <td id="">Hourly</td>
                                        <td id="dol_level1_hourly">{dolWageHourlyLevel1}</td>
                                        <td id="dol_level2_hourly">{dolWageHourlyLevel2}</td>
                                        <td id="dol_level3_hourly">{dolWageHourlyLevel3}</td>
                                        <td id="dol_level4_hourly">{dolWageHourlyLevel4}</td>

                                      </tr>

                                      <tr>
                                        <th scope="row" rowspan="2">Local Survey Data</th>

                                        <td id="">Yearly</td>
                                        <td id="com_level1">{surveyWageLevel1}</td>
                                        <td id="com_level2">{surveyWageLevel2}</td>
                                        <td id="com_level3">{surveyWageLevel3}</td>
                                        <td id="com_level4">{surveyWageLevel4}</td>

                                      </tr>


                                      <tr>
                                        <td id="">Hourly</td>
                                        <td id="com_level1_hourly">{surveyWageHourlyLevel1}</td>
                                        <td id="com_level2_hourly">{surveyWageHourlyLevel2}</td>
                                        <td id="com_level3_hourly">{surveyWageHourlyLevel3}</td>
                                        <td id="com_level4_hourly">{surveyWageHourlyLevel4}</td>

                                      </tr>




                                    </tbody>
                                  </table>
                                  <div className="p-4 wage-sur-inp">
                                  <div className="mb-3 text-center">
                                    <h4>Input Local Survey Data</h4>
                                    Please help us serve you better by providing wage information for your jobs. This information remains private and will not be shared with any other company or person. We only use this to calculate aggregated statistics at county and state level.
                                    Please submit an entry for each Job type/Job code.
                                  </div>
                                  <table class="table wage-data-tb2" >
                                    <thead class="thead-light">
                                      <tr >
                                        <th scope="col">Type</th>
                                        <th scope="col">LEVEL-1</th>
                                        <th scope="col">LEVEL-2</th>
                                        <th scope="col">LEVEL-3</th>
                                        <th scope="col">LEVEL-4</th>


                                      </tr>
                                    </thead>
                                    <tbody>

                                      <tr className="yearly-wd">
                                        <td id="">Yearly</td>
                                        <td id="">
                                          <input type="text" class=" allow_numericd" name="junior_y1" id="junior_y1" />
                                          <div id="junior_ycheck" class="error">Level1 yearly field is required.</div>
                                        </td>
                                        <td id="">
                                          <input type="text" class=" allow_numericd" name="midlevel_y1" id="midlevel_y1" />
                                          <div id="midlevel_ycheck" class="error">Level2 Yearly field is required.</div>
                                        </td>
                                        <td id="">
                                          <input type="text" class=" allow_numericd" name="manager_y1" id="manager_y1" />
                                          <div id="manager_ycheck" class="error">Level3 Yearly field is required.</div>
                                        </td>
                                        <td id="">
                                          <input type="text" class=" allow_numericd" name="excutive_y1" id="excutive_y1" />
                                          <div id="excutive_ycheck" class="error">Level4 Yearly field is required.</div>
                                        </td>

                                      </tr>


                                      <tr className="hourly-wd">
                                        <td id="">Hourly</td>
                                        <td id="">
                                          <input type="text" class=" allow_numeric3" name="junior_h1" id="junior_h1" />
                                          <div id="junior_hcheck" class="error">Level1 junior field is required.</div>
                                        </td>
                                        <td id="">
                                          <input type="text" class=" allow_numeric3" name="midlevel_h1" id="midlevel_h1" />
                                          <div id="midlevel_hcheck" class="error">Level2 Hourly field is required.</div>
                                        </td>
                                        <td id="">
                                          <input type="text" class=" allow_numeric3" name="manager_h1" id="manager_h1" />
                                          <div id="manager_hcheck" class="error">Level3 Hourly field is required.</div>
                                        </td>
                                        <td id="">
                                          <input type="text" class=" allow_numeric3" name="excutive_h1" id="excutive_h1" />
                                          <div id="excutive_hcheck" class="error">Level4 Hourly field is required.</div>
                                        </td>

                                      </tr>
                                      <tr>

                                        <td colspan="5" className="text-center py-3" >
                                          <button className="reg-button mt-2" onClick={() => submitWageData()}>Submit</button>
                                          <button onClick={closeModal} class="reg-button-close mx-2 mt-2">close</button>
                                        </td>


                                      </tr>




                                    </tbody>
                                  </table>
                                  <div id="successMessageWageSurvey" class="alert alert-success" role="alert">
                                        Wage survey data has been inserted successfully
                                    </div>
                                  </div>

                              </div>







                          </Modal>
                        </div>



                        <div class="d-flex"><label for="firstName" class="form-label mr-2">Salary/Benefits <span class="red-text">*</span></label></div>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          config={{
                            removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed"]
                          }}
                          id="salary"
                          onReady={editor => {
                            console.log('Editor is ready to use!', editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobSalary(data);
                            console.log({ event, editor, data });
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        /> */}

<SunEditor
                              setOptions={{
                                height: "400",
                                //buttonList: [['font', 'align'], ['image']]

                                buttonList: [
                                  ['undo', 'redo'],
                                  ['font', 'fontSize', 'formatBlock'],
                                  ['paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                  ['fontColor', 'hiliteColor', 'textStyle'],
                                  ['removeFormat'],
                                  '/', // Line break
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight'],
                                  ['table', 'link', 'image' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.                    
                                  ['fullScreen', 'showBlocks', 'codeView'],
                                  ['preview'],

                                  /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                ]
                                //buttonList: buttonList.complex
                                // plugins: [font] set plugins, all plugins are set by default
                                // Other option
                              }}
                              onChange={handleChangeJobSalaryDesc}                              
                              //onImageUpload={handleImageUpload}
                              onImageUploadBefore={handleImageUploadBefore}
                            />

                        <p id="jobSalaryValidMsg" class="error">Salary/Benefits is required.</p>

                        {/* <textarea class="form-control textarea" placeholder="Leave a comment here" id="salary" name="salary" spellcheck="false" {...register('salary', { required: true,maxLength:2000 })}></textarea>
                           {errors.salary && errors.salary.type === "required" && <div class="error">Salary/Benefits is required</div>}
                           {errors.salary && errors.salary.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}        */}
                      </div>




                      <div class="col-lg-12 mb-4">
                        <div class="d-flex"><label for="firstName" class="form-label mr-2 mb-3">Job Industry <span class="red-text" >*</span></label> <small>Max 3 Categories</small></div>

                        <div >
                          <div class="category-check-itm">
                            <ul class="category-check">
                              {
                                categories.map((data, index) => (


                                  <li><input class="form-check-input" type="checkbox" name="category" value={data.catId} id="category" {...register('category', { required: true, maxLength: 2000 })} onClick={() => validateCheck()} />  <label class="form-check-label" for="defaultCheck1"> {data.name} </label></li>

                                ))}
                              {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Accounting </label></li> */}
                              {/* <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advanced Manufacturing</label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Robotics </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> CNC  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Advertising and PR Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Agriculture and Food Processing  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Architectural and Design Services  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Art Athletic Training/Physical/ </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Occupational Therapy  </label></li>
                                        <li><input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>  <label class="form-check-label" for="defaultCheck1"> Auto/Diesel </label></li> */}
                            </ul>
                          </div>


                        </div>
                        {errors.category && errors.category.type === "required" && <div class="error">Job industry check atleast one</div>}
                        {errors.category && errors.category.type === "maxLength" && <div class="error">Should not allow more than 2000 character</div>}
                      </div>






                      <div class="col-lg-12 mb-4">

                        <div class="d-flex justify-content-between align-items-end choose">

                          <div><button type="submit" class="reg-button">post Job</button></div>
                        </div>

                      </div>

                      <div id="successMessage" class="alert alert-success" role="alert">
                        Job Posted Successfully</div>

                    </div>



                  </div>




                </div>

              </div>
            </div>
          </form>
        </section>

        <section class="load-wrap">
          <div class="loadingIcon">
            <img src="images/loading.gif"></img>
          </div>
        </section>

      </main>
    </>
  )
};

PostJob.propTypes = {};

PostJob.defaultProps = {};

export default PostJob;
