import React,{history,useEffect, useState, useMemo } from "react";
import Quick_links from "../utility/Quick_links";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import Hiring from "./Jobs/Hiring";
import services from "../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $, { parseJSON } from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import SidebarStudent from "../utility/sidebar";
import SidebarProfile from "../utility/sidebarProfile";

const WageData = () => {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [countyList, setCountyList] = useState([]);
    
    //const userId=138;
    const userId=sessionStorage.getItem("logUserId");
    const companyId=sessionStorage.getItem("logCompanyId");

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");




    const [imageName, setImageName] = useState('');
    const [imageExtension, setImageExtension] = useState('');
    const [banner, setBanner] = useState('');
    const [emaildefault, setemaildefault] = useState('');
    const [bannerBase64, setBannerBase64] = useState('');
    const [profilePicS3URL, setProfilePicS3URL] = useState('');
    const [collab, setCollabs] = useState('');
    const [socCode, setSocCode] = useState('');
    const [socCodeTitle, setSocCodeTitle] = useState('');
    const [jobCodeTitle, setJobCode] = useState('');
    const [wageList, setWageListObj] = useState('');

    const [dolWageLevel1, setDolWageLevel1] = useState('');
    const [dolWageLevel2, setDolWageLevel2] = useState('');
    const [dolWageLevel3, setDolWageLevel3] = useState('');
    const [dolWageLevel4, setDolWageLevel4] = useState('');

    const [dolWageHourlyLevel1, setDolWageHourlyLevel1] = useState('');
    const [dolWageHourlyLevel2, setDolWageHourlyLevel2] = useState('');
    const [dolWageHourlyLevel3, setDolWageHourlyLevel3] = useState('');
    const [dolWageHourlyLevel4, setDolWageHourlyLevel4] = useState('');

    const [surveyWageLevel1, setsurveyWageLevel1] = useState('');
    const [surveyWageLevel2, setsurveyWageLevel2] = useState('');
    const [surveyWageLevel3, setsurveyWageLevel3] = useState('');
    const [surveyWageLevel4, setsurveyWageLevel4] = useState('');
    
    const [surveyWageHourlyLevel1, setsurveyWageHourlyLevel1] = useState('');
    const [surveyWageHourlyLevel2, setsurveyWageHourlyLevel2] = useState('');
    const [surveyWageHourlyLevel3, setsurveyWageHourlyLevel3] = useState('');
    const [surveyWageHourlyLevel4, setsurveyWageHourlyLevel4] = useState('');



    
    

     //set default query terms
     const [query, setQuery] = useState("");
     let jobTitleGlobal = '';
      
     //get animated components wrapper
     const animatedComponents = makeAnimated();
   
    //var emaildefault = '';

    const {
        register,reset,
        formState: { errors },
        handleSubmit
      } = useForm({
        criteriaMode: "all",mode: "onBlur" 
      });


     
       
       // fetch filteres search results for dropdown
      /*  const loadOptions = () => {
          return fetch(`https://wagesurvey.smallnation.site/api/jobSugList?title=${query}`)
          .then((res) => res.json());
        }; */
        
        
  const loadOptions = () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
      },
      body: JSON.stringify({ title: query }), // Convert data to JSON format
    };
   
    return fetch('https://wagesurvey.smallnation.site/api/jobSugList', requestOptions)
      .then((res) => res.json());
  };

      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(reader.result);

          var base64result = reader.result.split(',')[1];
          //setBannerBase64(reader.result)
          setBannerBase64(base64result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

      function getImage(){
        var files = document.getElementById('banner').files;     
        
        if (files.length > 0) {
            var fname = files[0].name;
            var fextn = fname.split(".").pop();
            var fileActName = fname.split(".")[0];
            setImageName(fileActName);
            setImageExtension(fextn);           
          getBase64(files[0]);
        }
      }  
    useEffect(() => {
        const jsonData = {
            "wageData": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        $("#matchPwd").hide();
        $('#phone').usPhoneFormat();
        $( "#passwordTxtConf" ).blur(function() {
            var passwordConf = $("#passwordTxtConf").val();
            var passwordCheck = $("#passwordTxt").val();
            if(passwordConf != passwordCheck){
            $("#matchPwd").show();          
            }else{
            $("#matchPwd").hide();
            }
        });
       
          if(companyIdChkLogin == null || companyIdChkLogin == "null"){            
            //navigate('/login');
            navigate('/Home');
          }
        //getUserProfile();
        getStatesWage();
        
        
        $( "#stateId" ).change(function() {
            var st = $("#stateId").val()
            getCountyByState(st)
        });

        $( "#getWageInfo" ).click(function() {            
            getWageInfoCall()
        });

        setTimeout(() => {
            $( "#stateId" ).val('OH');
            getCountyByState('OH');
        }, 1000)

            
        
        
        
        $("#successMessage").hide();
        $("#imgError").hide();
 
    }, []);

    

    const getWageInfoCall = () => {
        var state = $("#stateId").val();
        var county = $("#countyId").val(); 
        var job_code = $("#jobCodeTitle").html();
        var soc_code    =$("#socCodeVal").html();
        

        //alert(socCodeTitle);      
       // alert(job_code);
        const requestObject = {
            "stateCode": state,      
            "county": county,      
            //"job_code": jobCode, 
            "job_code": job_code,           
            "soccode": soc_code,      

        }
        // console.log(JSON.stringify(objInputAvailableJobs));
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        services.getSurveyWageList(requestOptions).then((res) => {
           console.log(JSON.stringify(res))
            if(res){
                setWageListObj(res);   
                setDolWageLevel1('');
                setDolWageLevel2('');
                setDolWageLevel3('');
                setDolWageLevel4('');

                setDolWageHourlyLevel1('');
                setDolWageHourlyLevel2('');
                setDolWageHourlyLevel3('');
                setDolWageHourlyLevel4('');

                setsurveyWageLevel1('');
                setsurveyWageLevel2('');
                setsurveyWageLevel3('');
                setsurveyWageLevel4('');

                setsurveyWageHourlyLevel1('');
                setsurveyWageHourlyLevel2('');
                setsurveyWageHourlyLevel3('');
                setsurveyWageHourlyLevel4('');
                try{
                    if ( typeof(res.dolWage[0].level1) != "undefined" && res.dolWage[0].level1 != null ) {    
                       // alert(res.dolWage[0].level1);
                        setDolWageLevel1(res.dolWage[0].level1);
                        setDolWageLevel2(res.dolWage[0].level2);
                        setDolWageLevel3(res.dolWage[0].level3);
                        setDolWageLevel4(res.dolWage[0].level4);
                    }
                }catch(ex){

                }
                
              
                 try{
                    if ( typeof(res.dolWageHourly[0].level1) != "undefined" && res.dolWageHourly[0].level1 != null ) {     

                        setDolWageHourlyLevel1(res.dolWageHourly[0].level1);
                        setDolWageHourlyLevel2(res.dolWageHourly[0].level2);
                        setDolWageHourlyLevel3(res.dolWageHourly[0].level3);
                        setDolWageHourlyLevel4(res.dolWageHourly[0].level4);
                    }
                }catch(ex){
                    
                }
               
                

                try{
                    if ( typeof(res.surveyWage[0].level1) != "undefined" && res.surveyWage[0].level1 != null ) {     
                        setsurveyWageLevel1(res.surveyWage[0].level1);
                        setsurveyWageLevel2(res.surveyWage[0].level2);
                        setsurveyWageLevel3(res.surveyWage[0].level3);
                        setsurveyWageLevel4(res.surveyWage[0].level4);
                    }
                }catch(ex){
                    
                }
               
              

                try{
                    if ( typeof(res.surveyWageHourly[0].level1) != "undefined" && res.surveyWageHourly[0].level1 != null ) {         
                        setsurveyWageHourlyLevel1(res.surveyWageHourly[0].level1);
                        setsurveyWageHourlyLevel2(res.surveyWageHourly[0].level2);
                        setsurveyWageHourlyLevel3(res.surveyWageHourly[0].level3);
                        setsurveyWageHourlyLevel4(res.surveyWageHourly[0].level4);
                    }
                }catch(ex){
                    
                }
               
               
            }      
            
        });


        
     }

    const getCountyByState = (stateId) => {

        const requestObject = {
            "stateCode": stateId      

        }
        // console.log(JSON.stringify(objInputAvailableJobs));
        var accessTokenIn=sessionStorage.getItem("accToken");
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': "Bearer "+accessTokenIn 
                //'Authorization': accessTokenIn 
            },
            body: JSON.stringify(requestObject)
        };

        services.getCountyByStateFromWage(requestOptions).then((res) => {
           
            if(res){
                setCountyList(res);              
            }      
            
        });


        
     }

     const handleChange  = (selectedOption) => {
       //alert(JSON.stringify(selectedOption));
       var title = selectedOption.title;
       jobTitleGlobal = selectedOption.title;
       setJobCode(title);
       setSocCodeTitle(title);
       setSocCode(selectedOption.socCode);
     
     }

    const getStatesWage = () => {
        services.getStatesFromWage().then((res) => { 
            setStateList(res.data);        
        });
     }

    const getUserProfile = () => {
        services.getUserInfo(userId).then((res) => {
 
         setProfileDetails(res.data);
         $("#firstNameTxt").val(res.data.firstName);
         $("#lastNameTxt").val(res.data.lastName);
         $("#emailTxt").val(res.data.email);
         $("#phone").val(res.data.phone);
         //emaildefault = res.data.email;
         setemaildefault(res.data.email)
         setProfilePicS3URL(res.data.profilePicS3URL)
         // alert(profileDetails.name);
        });
     }

      const submitValue=()=>
      {
       //////////////////
        var pwd =  $("#passwordTxt").val();
       const requestObject =
       {
          "companyId": companyId,
          "siteId": process.env.React_App_SITE_ID,
          //"videoUrl": $("#videoUrl").val(),         
            "userId": userId,
            "firstName": $("#firstNameTxt").val(),
            "lastName":  $("#lastNameTxt").val(),
            //"email":  $("#emailTxt").val(),
            "email": emaildefault,
           //"password":  $("#passwordTxt").val(),
            "phone" : $("#phone").val(),
                  
 
       }
       if(pwd != ""){

        var passwordConf = $("#passwordTxtConf").val();
        var passwordCheck = $("#passwordTxt").val();
        if(passwordConf != passwordCheck){
         $("#matchPwd").show();
         $("#passwordTxtConf").focus();
         return false;
        }else{
         $("#matchPwd").hide();
        }

        requestObject['password'] = pwd;
       }
       if(imageName !=""){
        
        requestObject.fileName = imageName;
        requestObject.fileExtension = imageExtension;
        requestObject.pdfByString = bannerBase64;
        
       }
 
       console.log(requestObject);
       var accessTokenIn=sessionStorage.getItem("accToken");
       const requestOptions = {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
              'Authorization': "Bearer "+accessTokenIn 
              //'Authorization': accessTokenIn 
          },
          body: JSON.stringify(requestObject)
       };
       //editCompanyUser
       //alert("120");
       services.updateUserProfile(requestOptions).then((res) => {
          // navigate('/thankyou');
           // $("#editCompanyUserForm").trigger("reset");
           // getUserProfile();
           $("#successMessage").fadeTo(2000, 1000).slideUp(1000, function () {
            $("#successMessage").slideUp(1000);
          });
          if( companyIdChkLogin != null && companyIdChkLogin != "null"){   
            //setTimeout(function() {navigate('/companyJobs');}, 2000);
          }

         
       });



       //////////////
      }

  return(
  <>
<main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                            <ol class="breadcrumb">

                            {(() => {
                                
                                if( companyIdChkLogin != null && companyIdChkLogin != "null"){            
                                    return (<> <li class="breadcrumb-item headtitle "><a href="/companyJobs">Company Dashboard</a> </li>
                                    <li class="breadcrumb-item headtitle "><a href="#">Wage Data</a></li></>);
                                }

                               

                                })()}    


                               
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 ">
                        <div class="white-bg p-5">
                            <div class="row">
                                {/* <!-- left side --> */}

                                {(() => {
                                
                                if( companyIdChkLogin != null && companyIdChkLogin != "null"){            
                                    return (<><Quick_links/></>);
                                }

                              

                                })()}    
                               

                                {/* <!-- right side --> */}
                                <div class="col-lg-8 mb-4">
                                <form onSubmit={handleSubmit(submitValue)} id="editCompanyUserForm">
                                    


                                   


                                    <div class="row resume-wrap">
                                        <div class="col-lg-12 mb-4">
                                            <label for="firstName" class="form-label">State<span class="red-text">*</span></label>
                                           

                                            <select class="form-select" id="stateId">
                                        <option selected>List of States</option>
                                        {
                                        stateList.map((data, index) => (
                                        <option value={data.stateCode}>{data.stateName}</option>
                                        ))}
                                      </select>


                                        </div>

                                        <div class="col-lg-12 mb-4">
                                            <label for="firstName" class="form-label">County<span class="red-text">*</span></label>
                                            <select class="form-select" id="countyId">
                                        <option selected>List of Counties</option>
                                        {
                                        countyList.map((data, index) => (
                                        <option value={data.countyName}>{data.countyName}</option>
                                        ))}
                                      </select>
                                        </div>

                                        <div class="col-lg-12 mb-4">
                                            <label for="firstName" class="form-label">Standardized job code<span class="red-text">*</span></label>
                                            {/* <input type="text"  class="form-control" id="stjobcode"  defaultValue=""  {...register('stjobcode', { required: true, pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ })}/> */}
                                            

                                            <AsyncSelect
                                                cacheOptions                                                
                                                components={animatedComponents}
                                                getOptionLabel={(e) => e.title}
                                                getOptionValue={(e) => e.socCode}
                                                loadOptions={loadOptions}
                                                onInputChange={(value) => setQuery(value)}
                                                onChange={handleChange}
                                                // onChange={(value) => setSocCode(value)}
                                            />
                                        </div>

                                        <div class="col-lg-12 mb-4">
                                        <label for="phone" class="form-label">Soc Code:</label>
                                           <span id="socCodeVal">{socCode}</span>
                                        </div>


                                        <div class="col-lg-12 mb-4">
                                        <label for="phone" class="form-label">Job Title:</label>
                                           <span id="jobCodeTitle">{jobCodeTitle}</span>
                                        </div>

                                       
                                        <div class="col-lg-12 mb-4">                                       
                                            <button type="button" id="getWageInfo" class="reg-button">Search</button>
                                        </div>

                                       


                                        

                                      <div class="col-lg-12 mb-4">
                                      <table class="table wage-data-tb" >
                                                    <thead class="thead-light">
                                                    <tr style={{ border:"1px"}}>
                                                        <th scope="col">SOURCE</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">LEVEL-1</th>
                                                        <th scope="col">LEVEL-2</th>
                                                        <th scope="col">LEVEL-3</th>
                                                        <th scope="col">LEVEL-4</th>
                                                        
                                                    
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                       
                                                    <th scope="row"  rowspan="2">DOL Wage Data</th> 
                                                        <td id="">Yearly</td>
                                                        <td id="dol_level1">{dolWageLevel1}</td>
                                                        <td id="dol_level2">{dolWageLevel2}</td>
                                                        <td id="dol_level3">{dolWageLevel3}</td>
                                                        <td id="dol_level4">{dolWageLevel4}</td>
                                                       
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td id="">Hourly</td>
                                                        <td id="dol_level1_hourly">{dolWageHourlyLevel1}</td>
                                                        <td id="dol_level2_hourly">{dolWageHourlyLevel2}</td>
                                                        <td id="dol_level3_hourly">{dolWageHourlyLevel3}</td>
                                                        <td id="dol_level4_hourly">{dolWageHourlyLevel4}</td>
                                                       
                                                    </tr>
                                                    
                                                    <tr>
                                                        <th scope="row"  rowspan="2">Local Survey Data</th>

                                                        <td id="">Yearly</td>
                                                        <td id="com_level1">{surveyWageLevel1}</td>
                                                        <td id="com_level2">{surveyWageLevel2}</td>
                                                        <td id="com_level3">{surveyWageLevel3}</td>
                                                        <td id="com_level4">{surveyWageLevel4}</td>
                                                        
                                                    </tr>
                                                    
                                                    
                                                    <tr>
                                                        <td id="">Hourly</td>
                                                        <td id="com_level1_hourly">{surveyWageHourlyLevel1}</td>
                                                        <td id="com_level2_hourly">{surveyWageHourlyLevel2}</td>
                                                        <td id="com_level3_hourly">{surveyWageHourlyLevel3}</td>
                                                        <td id="com_level4_hourly">{surveyWageHourlyLevel4}</td>
                                                   
                                                    </tr>
                                                    
                                                    </tbody>
                                                </table>
                                      </div>

                                      <div class="col-lg-12 mb-4">
                                            <p>
                                            Please help us improve this data by participating in our wage survey This information you provide remains private and will not be shared with any other company or person. We only use this to calculate aggregated statistics at county and state level.
                                            </p>
                                        </div>

                                      <div class="col-lg-4 mb-2">                                       
                                            
                                        </div>
                                        
                                        <div class="col-lg-8 mb-8">                                       
                                            <a href="/wageSurveyData" class="reg-button-wage">Participate in Wage Survey</a>
                                        </div>
                                        <div class="col-lg-4 mb-2">                                       
                                            
                                        </div>




                                        <div id="successMessage" class="alert">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img src="/images/success-alert.png" alt=""/>
                                                    <strong class="mx-2">Success!</strong> Details updated successfully.
                                                </div>
                                                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                                            </div>
                                        </div>


                                        
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        {/* <Hiring/> */}
    </main>
  </>
)};

WageData.propTypes = {};

WageData.defaultProps = {};

export default WageData;
