import React,{history,useEffect, useState, useMemo } from "react";
import Recent_Jobs from "./Jobs/Recent_Jobs";
import services from "../services/services";
import Hiring from "./Jobs/Hiring";

const EducationTraining = () => {

    const companyIdChkLogin=sessionStorage.getItem("logCompanyId");
    const studentIdChkLogin=sessionStorage.getItem("logStudentId");
    const jobSeekerIdChkLogin=sessionStorage.getItem("logProfileId");
    const userIdChkLogin=sessionStorage.getItem("logUserId");
    const chKRoleAr=sessionStorage.getItem("roleAr");

    const [leisureContent, setLeisureContent] = useState([]);
    const getPageInfo = (reqopt) => {


        services.getCMSPageContent('15').then((res) => {

            console.log(JSON.stringify(res));
            if(res.data){
               setLeisureContent(res.data.content.rendered);
            }

        });

     }

    useEffect(() => {
        const jsonData = {
            "educationTraining": {
              
            }
          };
          var jsondataformatted = JSON.stringify(jsonData);
          sessionStorage.setItem('pagejsonObject', jsondataformatted);

        getPageInfo("downloadourapp");

    }, []);
  return(
  <>
 <main class="empr-directory-main">
        <div class="main-bgpatten"></div>
        {/* <!-- info typography --> */}
        <section class="headtitle-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                    <nav style={{"--bs-breadcrumb-divider": "'>';"}} aria-label="breadcrumb">
                        <ol class="breadcrumb">
                                {/* <li class="breadcrumb-item headtitle ">Company Dashboard <span style={{color: "#6c757d"}}>&#62;</span></li> */}

                                {(() => {

                                    if( jobSeekerIdChkLogin != null && jobSeekerIdChkLogin != "null"){
                                        return (<><li class="breadcrumb-item headtitle"> <a href="/profileDashboard">Job Seeker Dashboard</a> </li> </>);
                                    }

                                    if( chKRoleAr == "schooladmin"  || chKRoleAr == "schoolsuper" ){
                                    return (<><li class="breadcrumb-item headtitle"> <a href="/schoolAdminDashboard">School Admin</a> </li> </>);
                                    }



                                    if( studentIdChkLogin != null && studentIdChkLogin != "null"){
                                        return (<><li class="breadcrumb-item headtitle"> <a href="/studentDashboard">Student Dashboard</a> </li> </>);
                                    }

                                    })()}
                                <li class="breadcrumb-item active headtitle "><a href="#"> Colleges & Universities</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- info typography --> */}
        <section class="py-5">
            <div class="container">
                <div class="row">
                    {/* <!-- right side --> */}
                    <div class="col-lg-8 mb-4">

                        <div class="p-4 white-bg">
                            {/* <div dangerouslySetInnerHTML={{ __html: leisureContent  }} /> */}

                            <div class="etr">

<ul class="image-list _3col">
 	<li><a href="https://www.apollocareercenter.com/adult-education/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/apollologo.jpg"  alt="Apollo" border="0"  /></a></li>
 	<li><a href="https://www.ashland.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/ashland.png" alt="Ashland" border="0" /></a></li>
 	<li><a href="https://www.bsu.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/ballstate.png" alt="Ball State" border="0" /></a></li>
 	<li><a href="https://www.bluffton.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/bluffton.jpg" alt="Bluffton" border="0" /></a></li>
 	<li><a href="https://www.bgsu.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/bgsu.jpg" alt="BGSU" /></a></li>

    <li><a href="https://www.capital.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/capitaluniversity.jpg" alt="Capital" border="0" /></a></li>
 	<li><a href="https://www.cedarville.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/cedarville.jpg" alt="cedarvill" border="0" /></a></li>
 	<li><a href="https://www.clarkstate.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/clark-logo.png" alt="clarkstate" border="0" /></a></li>
 	<li><a href="https://www.cscc.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/columbus.jpg" alt="Columbus State" border="0" /></a></li>
 	<li><a href="http://www.defiance.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/defiance.jpg" alt="defiance" border="0" /></a></li>

    <li><a href="https://www.edisonohio.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/edison-state.png" alt="Edison State Community College" border="0" /></a></li>
    <li><a href="https://earlham.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/earlham.png" alt="Earlham College" border="0" /></a></li>
    <li><a href="https://www.heidelberg.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/heideberg.jpg" alt="heidelberg" border="0" /></a></li>
 	<li><a href="https://www.welding.org/?doing_wp_cron=1662029596.2038500308990478515625" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/hobart.jpg" alt="Hobart Welding School" border="0" /></a></li>
    <li><a href="https://www.indianatech.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/indianatech.png" alt="Indiana Tech" border="0" /></a></li>
    <li><a href="https://www.indwes.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/iwu.jpg" alt="Indiana Wesleyan University" border="0" /></a></li>

 	<li><a href="https://www.kent.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/kent-state-university.png" alt="Kent Campus" border="0" /></a></li>
    <li><a href="https://www.kettering.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/kettering.png" alt="Kettering" border="0" /></a></li>
    <li><a href="https://miamioh.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/miami-university-logo.png" alt="miamioh" border="0" /></a></li>
 	<li><a href="https://www.mvctc.com/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/miami-valley.jpg" alt="wright state" border="0" /></a></li>
    <li><a href="https://www.onu.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/ohio-northern-uni.png" alt="ONU" border="0" /></a></li>

    <li><a href="https://www.ohio.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/ohio_university_logo.png" alt="OU" border="0" /></a></li>
    <li><a href="https://www.pfw.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/purdue-university.jpg" alt="Purdue University Fort Wayne" border="0" /></a></li>
    <li><a href="https://www.rhodesstate.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/rhodes.jpg" alt="Rhodes State College" border="0" /></a></li>
 	<li><a href="https://www.sinclair.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/sinclair-logo.png" alt="Sinclair College" border="0" /></a></li>
    <li><a href="https://www.taylor.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/tylore-university.png" alt="Taylore" border="0" /></a></li>
    <li><a href="https://www.osu.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/the-ohio-state.jpg" alt="OSU" border="0" /></a></li>

 	<li><a href="https://lima.osu.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/osulimalogo.png" alt="OSU Lima" border="0" /></a></li>
    <li><a href="https://www.tiffin.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/tiffini-university.png" alt="Tiffin" border="0" /></a></li>
 	<li><a href="https://www.tristarcareercompact.com/adult-classes" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/tristarlogo.png" alt="Tristar" border="0" /></a></li>
    <li><a href="https://www.uakron.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/UAWordmark_blue.png" alt="Uakron" border="0" /></a></li>
    <li><a href="https://www.uc.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/u-cincinati.png" alt="Cincinnati" border="0" /></a></li>

    <li><a href="https://udayton.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/u-dayton.png" alt="udayton" border="0" /></a></li>
    <li><a href="https://www.findlay.edu/about-uf/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/u-findlay.png" alt="vantage" border="0" /></a></li>
 	<li><a href="https://www.utoledo.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/toledo.png" alt="wright state" border="0" /></a></li>
    <li><a href="https://www.unoh.edu/about/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/unohlogo.png" alt="upper valley" border="0" /></a></li>
    <li><a href="https://www.uppervalleycc.org/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/uppervalley.png" alt="vantage" border="0" /></a></li>

    <li><a href="https://www.vantagecareercenter.com/adulteducation_home.aspx" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/vantage.jpg" alt="Vintage" border="0" /></a></li>
    <li><a href="https://www.wilmington.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/wilmin.png" alt="wilmington Colege" border="0" /></a></li>
 	<li><a href="https://www.wright.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/wsu.png" alt="WSU" border="0" /></a></li>
    <li><a href="https://lake.wright.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/04/wrightstate.png" alt="WSU Lake" border="0" /></a></li>
    <li><a href="https://www.xavier.edu/" target="_blank"><img src="https://snv3cms.smallnation.site/wp-content/uploads/2022/09/xaviar.png" alt="xavier" border="0" /></a></li>


</ul>

</div>

                         </div>

                    </div>

                    {/* <!-- left side --> */}
                    <div class="col-lg-4">
                    <Recent_Jobs/>
                      </div>

                </div>
            </div>
        </section>

        <Hiring/>


    </main>
  </>
)};

EducationTraining.propTypes = {};

EducationTraining.defaultProps = {};

export default EducationTraining;
